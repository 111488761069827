import React from 'react';

const shapeAPoints =
  'M499.7410281922859,190.38997160238628 C401.5,184.5 298.6493971436316,157.2394095711992 162.59749673168213,69.15149492809776 C109.82661563576669,35.08303613757203 55.677479728890674,12.02430966652371 5.684341886080802e-14,0 L5.684341886080802e-14,682 L601.1644975033789,682 C601.1644975033789,646.3839802063789 620.5,624.5 637.5519768351605,600.5559601813942 C682.8766496378194,536.9119744521622 688.5,527.5 705.7965738919197,459.8863661348651 C723.0931477838392,392.2727322697302 716.5535530371199,336.0413920052198 676.5420566826081,277.9005434842234 C630.1206674878033,210.44816331941936 570.0000000000002,196.50000000000003 499.7410281922859,190.38997160238628Z';

const shapeBPoints =
  'M510.00000000000006,154.5 C411.7589718077143,148.61002839761372 333.5519004119496,192.58791464310144 197.5000000000001,104.5 C144.7291189040847,70.43154120947426 55.677479728890674,12.02430966652371 5.684341886080802e-14,0 L5.684341886080802e-14,682 L601.1644975033789,682 C601.1644975033789,646.3839802063789 620.5,624.5 637.5519768351605,600.5559601813942 C682.8766496378196,536.9119744521622 620.255402943241,496.6136338651349 637.5519768351605,429 C654.8485507270802,361.3863661348651 699.0000000000002,320.5 667.5000000000002,254.5 C636.0000000000002,188.49999999999997 580.2589718077143,160.61002839761375 510.00000000000006,154.5Z';

export default ({ fill = '#ffffff' }) => (
  <svg viewBox="0 0 730 682">
    <g
      transform="translate(354.50000000000006, 341) scale(-1, 1) translate(-354.50000000000006, -341) translate(354.50000000000006, 341) rotate(180.00006436155007) translate(-354.50000000000006, -341)"
      opacity="1"
    >
      <path d={shapeAPoints} fillRule="nonzero" fill={fill}>
        <animate
          dur="30s"
          repeatCount="indefinite"
          attributeName="d"
          values={`${shapeAPoints};${shapeBPoints};${shapeAPoints}`}
          fill="freeze"
          calcMode="spline"
          keySplines="0.4 0 0.2 1; 0.4 0 0.2 1"
        />
      </path>
    </g>
  </svg>
);
