import Link from 'gatsby-link';
import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../lib/constants';
import AnimatedBlobHeroSvg from '../AnimatedBlobHeroSvg';
import ContentWrapper from '../reusables/ContentWrapper';
import TopNav from '../TopNav';
import WaveContainer from '../WaveContainer';
import heroImage from '../../assets/hero.svg';

const WHITE_BLOCK_WIDTH = 500;

const Wrapper = styled.div({
  backgroundColor: '#C3CCDB',
  backgroundImage: `url(${heroImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center right',
  height: 582,
  position: 'relative',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    height: 'auto',
    backgroundPosition: 'center center',
  },
});
const scales = [[1.02, 1.02], [1.04, 1.03], [1.05, 1.04], [1.02, 1.02]];

const WhiteBlob = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: rotate(360deg);
  min-width: ${WHITE_BLOCK_WIDTH}px;
  overflow: hidden;
  svg {
    height: 125%;
    right: 0;
    top: 0;
    width auto;
    position: absolute;
  }
`;

const Content = styled.div({
  width: '50%',
  maxWidth: WHITE_BLOCK_WIDTH + 50,
  paddingRight: 20,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    minWidth: '100%',
    width: '100%',
    paddingRight: 0,
  },
});

const Heading = styled.h1({
  fontSize: '48px',
  lineHeight: 1.1,
  textTransform: 'uppercase',
  marginBottom: 20,
  marginTop: 60,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    fontSize: '36px',
    lineHeight: '36px',
    marginTop: 0,
  },
});

const Desktop = styled.div({
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'none',
  },
});

const Mobile = styled.div({
  display: 'none',
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});
const Main = ({
  startPageHeroHeading,
  startPageHeroLinkText,
  startPageHeroLinkUrl,
}) => (
  <Content>
    <ContentWrapper css={{ position: 'relative' }}>
      <Heading>{startPageHeroHeading}</Heading>
      <a css={{ textDecoration: 'underline' }} href={startPageHeroLinkUrl}>
        {startPageHeroLinkText}
      </a>
    </ContentWrapper>
  </Content>
);

export default props => (
  <Wrapper>
    <Desktop>
      <WhiteBlob>
        <AnimatedBlobHeroSvg />
      </WhiteBlob>
      <TopNav />
      <Main {...props} />
    </Desktop>
    <Mobile>
      <TopNav white />
      <div css={{ paddingTop: '30vh' }}>
        <WaveContainer fill="#fff">
          <Main {...props} />
        </WaveContainer>
      </div>
    </Mobile>
  </Wrapper>
);
