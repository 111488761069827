import styled from 'styled-components';

import { breakpoints } from '../lib/constants';

const RowColumnResponsiveConveter = styled.div({
  display: 'grid',
  gridTemplateColumns: '350px 1fr',
  columnGap: 80,
  [`@media (max-width: ${breakpoints.oneColumn}px)`]: {
    display: 'block',
  },
});

export default RowColumnResponsiveConveter;
