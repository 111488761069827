import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import { breakpoints, gutters, fonts } from '../../lib/constants';
import SingleCase from '../case/SingleCase';

const Items = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: ${gutters.vertical}px;
  @media (max-width: ${breakpoints.oneColumn}px) {
    display: block;
  }
  *:nth-child(1) {
    grid-column: 1 / 3;
  }
`;

function FeaturedCases({ featuredCases }) {
  return (
    <>
      <Items>
        {featuredCases.map((node, i) => {
          return <SingleCase {...node} key={i} />;
        })}
      </Items>
    </>
  );
}

export default FeaturedCases;
