import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { breakpoints } from '../../lib/constants';
import Button from '../reusables/Button';
import FeaturedArticle from '../FeaturedArticle';
import SectionTitle from '../reusables/SectionTitle';

const Container = styled.div({ marginBottom: 50 });
const ButtonContainer = styled.div({
  marginTop: 0,
  fontSize: 14,
  textDecoration: 'underline',
});

function FeaturedArticles({ featuredArticles }) {
  return (
    <Container>
      <SectionTitle>BETEENDEBLOGGEN & NYHETER</SectionTitle>
      {featuredArticles.map((node) => {
        return <FeaturedArticle {...node} key={node.title} />;
      })}
      <ButtonContainer>
        <Link to="/latest">
          Fler blogginlägg och nyheter
        </Link>
      </ButtonContainer>
    </Container>
  );
}

export default FeaturedArticles;
