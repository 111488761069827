import { Link, graphql } from 'gatsby';
import React from 'react';

import AppChrome from '../components/AppChrome';
import Button from '../components/reusables/Button';
import CalendarPanel from '../components/CalendarPanel';
import CenterChild from '../components/reusables/CenterChild';
import ContentWrapper from '../components/reusables/ContentWrapper';
import FeaturedArticles from '../components/start/FeaturedArticles';
import FeaturedCases from '../components/start/FeaturedCases';
import Footer from '../components/Footer';
import RowColumnResponsiveConveter from '../components/RowColumnResponsiveConveter';
import SectionTitle from '../components/reusables/SectionTitle';
import StartPageHero from '../components/start/StartPageHero';
import WhatWeDo from '../components/start/WhatWeDo';

function RootIndex({ data }) {
  const node = data.allContentfulSettings.edges[0].node;
  const {
    featuredArticles,
    featuredCases,
    startPageHeroHeading,
    startPageHeroLinkText,
    startPageHeroLinkUrl,
  } = node;
  return (
    <AppChrome
      og={{
        title: 'Beteendelabbet',
        description: `
          Vi kombinerar design och psykologi för att driva affärsnytta och skapa
          vinster för människan, organisationen & planeten.
        `,
      }}
      footer={
        <WhatWeDo>
          <Footer />
        </WhatWeDo>
      }
    >
      <StartPageHero
        {...{
          startPageHeroHeading,
          startPageHeroLinkText,
          startPageHeroLinkUrl,
        }}
      />
      <ContentWrapper>
        <RowColumnResponsiveConveter>
          <div>
            <FeaturedArticles {...{ featuredArticles }} />
            <CalendarPanel events={data.allContentfulEvent.edges} />
          </div>
          <div>
            <SectionTitle>CASE</SectionTitle>
            <FeaturedCases {...{ featuredCases }} />
            <CenterChild>
              <Button css={{ marginTop: 50 }} as={Link} to="/cases">
                ALLA CASE
              </Button>
            </CenterChild>
          </div>
        </RowColumnResponsiveConveter>
      </ContentWrapper>
    </AppChrome>
  );
}

export default RootIndex;

export const pageQuery = graphql`
  query {
    allContentfulEvent(sort: { fields: [date], order: ASC }) {
      edges {
        node {
          date(formatString: "DD MMM")
          link
          linkText
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulSettings {
      edges {
        node {
          startPageHeroHeading
          startPageHeroLinkUrl
          startPageHeroLinkText
          featuredCases {
            title
            slug
            client
            heroImage {
              sizes(maxWidth: 1420, maxHeight: 1000, resizingBehavior: FILL) {
                ...GatsbyContentfulSizes_withWebp
              }
            }
          }
          featuredArticles {
            title
            slug
            category {
              title
              slug
            }
            image {
              sizes(maxWidth: 640, maxHeight: 464, resizingBehavior: FILL) {
                ...GatsbyContentfulSizes_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
